import { useSiteGetters } from '@/use/useSiteGetters';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import {
	SiteBlock,
	SiteBuilderCompletedSteps,
	SiteData,
	SiteElement,
	SiteElements,
	SiteLanguages,
	SitePage,
	SiteMeta,
	SiteStyles,
	SiteEcommerceSeoChanges,
	// siteSchemaValidator,
	SiteLanguage,
	SiteFont,
} from '@hostinger/builder-schema-validator';
import {
	FONT,
	PROPERTY_FONT_PRIMARY,
	PROPERTY_FONT_SECONDARY,
} from '@zyro-inc/site-modules/constants/globalStyles';
import { extractFontName } from '@zyro-inc/site-modules/utils/font';
import { defineStore } from 'pinia';
import {
	computed,
	ref,
	watch,
} from 'vue';
import { useStore } from 'vuex';
import { PINIA_STORES } from '@/constants/stores';
import {
	addBreadcrumb,
	captureException,
} from '@sentry/vue';

const MALFORMED_FONTS = ['latin'];
const fallbackFont = "'Roboto',sans-serif";

export const useSiteStore = defineStore(PINIA_STORES.SITE, () => {
	const {
		dispatch,
		getters,
	} = useStore();

	const schemaValidationError = (error: any) => {
		// eslint-disable-next-line no-console
		console.log(error);
		dispatch('notifications/notify', {
			message: 'Unexpected error occurred. Try reloading the page.',
		});
	};

	const site = ref<SiteData | Record<string, never>>({});
	const addElementData = ref<SiteElement | Record<string, never>>({});

	const websiteId = ref('');
	const currentPageId = ref('');
	const currentBlockId = ref('');
	const currentElementId = ref('');

	const {
		currentLocale,
		siteLanguages,
		siteLanguagesList,
		hasLanguages,
		currentSiteLanguage,
		siteBlocks,
		siteElements,
		sitePages,
		siteNav,
		isNavHidden,
		blogReadingTimeText,
		homePageId,
		hResourceId,
		ecommerceProductPages,
		ecommerceDynamicProductPageTemplates,
		ecommerceLegacyProductPages,
		defaultLocale,
		ecommerceShoppingCart,
		siteForms,
		siteStyles,
		siteTemplate,
		hasBlankTemplate,
		hasGeneratedTemplate,
		hasImportedTemplate,
		templateType,
		siteHomePageTitle,
		siteFonts,
		currentPage,
		currentBlock,
		currentBlockType,
		currentBlockSettings,
		currentBlockStyles,
		currentBlockSlot,
		currentElement,
		currentElementContent,
		currentElementSettings,
		currentElementStyles,
		currentElementType,
		currentElementBlockId,
		currentElementBlock,
		currentElementBlockType,
		getElementBLockId,
		headerBlock,
		footerBlock,
		doesFooterExist,
		doesPageIdAlreadyExist,
		isCurrentPageTypeBlog,
		isCurrentPageEmpty,
		blogCategories,
		isLanguageSwitcherHidden,
		defaultPages,
		blogPages,
		draftBlogPages,
		scheduledBlogPages,
		publishedBlogPages,
		builderCompletedSteps,
		siteMeta,
		isPrivateModeActive,
		aiSalesAssistant,
		isCurrentPagePrivate,
		isCurrentPageTypeEcommerceProduct,
		ecommerceSeoChanges,
	} = useSiteGetters({
		site,
		currentPageId,
		currentBlockId,
		currentElementId,
	});

	const isCustomCodeDisabled = computed(() => siteMeta.value.isCustomCodeDisabled || false);

	const setSiteCustomCodeDisabled = (value: boolean) => {
		site.value.meta.isCustomCodeDisabled = value;
	};

	const setSiteId = (id: string) => {
		websiteId.value = id;
	};

	const setSiteCurrentPageId = (id: string) => {
		currentPageId.value = id;
	};

	const setSiteCurrentBlockId = (id: string) => {
		currentBlockId.value = id;
	};

	const setSiteCurrentElementId = (id: string) => {
		currentElementId.value = id;
	};

	const setSiteHomePageId = ({
		locale = SYSTEM_LOCALE,
		id,
	}: { locale: string, id: string }) => {
		site.value.languages[locale].homePageId = id;
	};

	const setSiteData = (data: SiteData) => {
		try {
			// siteSchemaValidator.validateSiteData(data);

			site.value = data;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteLanguagesData = (data: SiteLanguages) => {
		try {
			// siteSchemaValidator.validateSiteLanguages(data);

			site.value.languages = data;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteLanguageData = ({
		locale = SYSTEM_LOCALE,
		data,
	}: { locale: string, data: SiteLanguage}) => {
		try {
			// siteSchemaValidator.validateSiteLanguage(data);

			site.value.languages[locale] = data;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSitePageData = ({
		locale = SYSTEM_LOCALE,
		pageId,
		data,
	}: { locale: string, pageId: string, data: SitePage }) => {
		try {
			// siteSchemaValidator.validateSitePage(data);

			site.value.languages[locale].pages = {
				...site.value.languages[locale].pages,
				[pageId]: data,
			};
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteBlockData = ({
		locale = SYSTEM_LOCALE,
		blockId,
		data,
	}: { locale: string; blockId: string; data: SiteBlock; }) => {
		try {
			// siteSchemaValidator.validateSiteBlock(data);

			site.value.languages[locale].blocks[blockId] = data;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteAddElementData = (data: SiteElement) => {
		try {
			// siteSchemaValidator.validateSiteElement(data);

			addElementData.value = data;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteElementData = ({
		locale = SYSTEM_LOCALE,
		elementId,
		data,
	}: { locale: string, elementId: string, data: SiteElement }) => {
		try {
			// siteSchemaValidator.validateSiteElement(data);

			site.value.languages[locale].elements = {
				...site.value.languages[locale].elements,
				[elementId]: data,
			};
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteElementsData = ({
		locale = SYSTEM_LOCALE,
		data,
	}: { locale: string, data: SiteElements }) => {
		try {
			const elementLocale = isCurrentPagePrivate.value ? SYSTEM_LOCALE : locale;

			site.value.languages[elementLocale].elements = {
				...site.value.languages[elementLocale].elements,
				...data,
			};
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteMetaData = ({
		key,
		value,
	}: { key: string, value: SiteMeta[keyof SiteMeta]}) => {
		try {
			const newMeta: SiteMeta = {
				...site.value.meta,
				[key]: value,
			};

			// siteSchemaValidator.validateSiteMeta(newMeta);

			site.value.meta = newMeta;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteFontsData = (value: SiteFont[]) => {
		try {
			// siteSchemaValidator.validateSiteFonts(value);

			site.value.fonts = value;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteStyleProperties = ({
		element,
		value,
	}: { element: string, value: any }) => {
		try {
			const newStyles: SiteStyles = {
				...site.value.styles,
				[element]: {
					...(site.value.styles as any)[element],
					...value,
				},
			};

			// siteSchemaValidator.validateSiteStyles(newStyles);

			site.value.styles = newStyles;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteStyleProperty = ({
		element,
		property,
		value,
	}: { element: string, property: string, value: any }) => {
		try {
			const newStyles: SiteStyles = {
				...site.value.styles,
				[element]: {
					...(site.value.styles as any)[element],
					[property]: value,
				},
			};

			// siteSchemaValidator.validateSiteStyles(newStyles);

			site.value.styles = newStyles;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteBuilderCompletedStepsData = (steps: SiteBuilderCompletedSteps) => {
		try {
			// siteSchemaValidator.validateSiteBuilderCompletedSteps(steps);

			site.value.builderCompletedSteps = steps;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const removeNonExistingBlocksFromSite = ({
		pageId,
		nonExistingBlocks,
	}: { pageId: string, nonExistingBlocks: string[] }) => {
		const page = site.value.languages[currentLocale.value].pages[pageId];

		page.blocks = [...(page.blocks || []).filter((blockId) => !nonExistingBlocks.includes(blockId))];
	};

	/**
	 * Check if page slug is unique. If `slugPageId` is provided, page will be excluded from the check.
	 */
	const getIsPageSlugUnique = ({
		slug,
		slugPageId,
	}: {
		slug: string,
		slugPageId?: string
	}): boolean => {
		const matchingSlugs = Object.entries(sitePages.value).filter(
			([pageId, page]) => {
				if (pageId) {
					return pageId !== slugPageId && page.slug === slug;
				}

				return page.slug === slug;
			},
		);

		return matchingSlugs.length === 0;
	};

	const setEcommerceSeoChanges = (seoChanges: SiteEcommerceSeoChanges[]) => {
		try {
			const existingUnpublishedChanges = site.value.ecommerceSeoChanges || [];

			const uniqueExistingUnpublishedChanges = existingUnpublishedChanges
				.filter(
					(existingChange) => !seoChanges
						.some((newChange) => existingChange.id === newChange.id),
				);

			site.value.ecommerceSeoChanges = [
				...uniqueExistingUnpublishedChanges,
				...seoChanges,
			];
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const resetEcommerceSeoChanges = () => {
		site.value.ecommerceSeoChanges = [];
	};

	// TODO remove after root cause fixed
	// `family: 'latin'` font appears some site font data, and causes 404 errors.
	// How it appears is not clear the goal to improve error logging and patch broken font.
	watch(() => site.value.styles?.font, async (newFont, initialFont) => {
		const initialPrimaryFont = initialFont?.[PROPERTY_FONT_PRIMARY];
		const initialSecondaryFont = initialFont?.[PROPERTY_FONT_SECONDARY];

		const newPrimaryFont = newFont?.[PROPERTY_FONT_PRIMARY];
		const newSecondaryFont = newFont?.[PROPERTY_FONT_SECONDARY];

		const didPrimaryFontChange = newPrimaryFont !== initialPrimaryFont;
		const didSecondaryFontChange = newSecondaryFont !== initialSecondaryFont;

		const didFontsChange = didSecondaryFontChange || didPrimaryFontChange;

		if (didFontsChange && (newPrimaryFont || newSecondaryFont)) {
			const isPrimaryFontMalformed = MALFORMED_FONTS.includes(extractFontName(newPrimaryFont));
			const isSecondaryFontMalformed = MALFORMED_FONTS.includes(extractFontName(newSecondaryFont));

			if (isPrimaryFontMalformed || isSecondaryFontMalformed) {
				addBreadcrumb({
					category: 'MALFORMED_FONT_SET',
					message: 'Malformed primary or secondary font has been used',
					data: {
						isPrimaryFontMalformed,
						isSecondaryFontMalformed,
						didSecondaryFontChange,
						didPrimaryFontChange,
						initialPrimaryFont,
						newPrimaryFont,
						initialSecondaryFont,
						newSecondaryFont,
					},
					level: 'debug',
					type: 'debug',
				});

				captureException(new Error(('Malformed font has been set')));

				const fixedFont = {
					primary: isPrimaryFontMalformed ? fallbackFont : newPrimaryFont,
					secondary: isSecondaryFontMalformed ? fallbackFont : newSecondaryFont,
				};

				if (fixedFont.primary && fixedFont.secondary) {
					setSiteStyleProperties({
						element: FONT,
						value: fixedFont,
					});

					if (getters['saving/canSave']) {
						await dispatch('saving/saveWebsite', {
							saveWhenImpersonating: true,
						});
					}
				}
			}
		}
	}, {
		immediate: true,
	});

	return {
		websiteId,
		site,
		siteMeta,
		isPrivateModeActive,
		aiSalesAssistant,
		currentBlockId,
		currentElementId,
		currentPageId,
		addElementData,
		currentLocale,
		siteLanguages,
		siteLanguagesList,
		hasLanguages,
		currentSiteLanguage,
		siteBlocks,
		siteElements,
		sitePages,
		siteNav,
		isNavHidden,
		blogReadingTimeText,
		homePageId,
		hResourceId,
		ecommerceProductPages,
		ecommerceDynamicProductPageTemplates,
		ecommerceLegacyProductPages,
		defaultLocale,
		ecommerceShoppingCart,
		siteForms,
		siteStyles,
		siteTemplate,
		hasGeneratedTemplate,
		hasImportedTemplate,
		hasBlankTemplate,
		templateType,
		siteHomePageTitle,
		siteFonts,
		currentPage,
		currentBlock,
		currentBlockType,
		currentBlockSettings,
		currentBlockStyles,
		currentBlockSlot,
		currentElement,
		currentElementContent,
		currentElementSettings,
		currentElementStyles,
		currentElementType,
		currentElementBlockId,
		currentElementBlock,
		currentElementBlockType,
		getElementBLockId,
		headerBlock,
		footerBlock,
		doesFooterExist,
		doesPageIdAlreadyExist,
		isCurrentPageTypeBlog,
		isCurrentPageTypeEcommerceProduct,
		isCurrentPageEmpty,
		getIsPageSlugUnique,
		blogCategories,
		isLanguageSwitcherHidden,
		defaultPages,
		blogPages,
		draftBlogPages,
		scheduledBlogPages,
		publishedBlogPages,
		builderCompletedSteps,
		ecommerceSeoChanges,
		setSiteId,
		setSiteCurrentElementId,
		setSiteCurrentPageId,
		setSiteCurrentBlockId,
		setSiteData,
		setSitePageData,
		setSiteBlockData,
		setSiteElementData,
		setSiteElementsData,
		setSiteLanguagesData,
		setSiteHomePageId,
		setSiteBuilderCompletedStepsData,
		setSiteMetaData,
		setSiteFontsData,
		setSiteAddElementData,
		setSiteStyleProperties,
		setSiteStyleProperty,
		removeNonExistingBlocksFromSite,
		setSiteLanguageData,
		isCustomCodeDisabled,
		setSiteCustomCodeDisabled,
		setEcommerceSeoChanges,
		resetEcommerceSeoChanges,
	};
});
